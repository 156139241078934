<template>
  <div class="container-cental">
    <h1 class="mt-5 mb-5">PEDIDOS</h1>
    <div class="container-filters">
      <div>        
          <b-form-checkbox-group
            id="checkbox-group"
            v-model="selectedStatus"
            :options="estados"
            class="m-0"
          ></b-form-checkbox-group>        
      </div>
      <hr class="mb-3 separador">
      <div class="container-calendar">
        <b-form inline>
          <label for="fecha-desde" class="mr-2">Entregas Planificadas Desde</label>
          <b-form-datepicker
            id="fecha-desde"
            v-model="fechaDesde"
            class="mb-2"
          ></b-form-datepicker>
        </b-form>
      </div>
      <hr class="mb-3 separador">
      <div style="display:flex;align-items:center;justify-content:center">
        <b-button @click="verificarPedidos()" size="md"
          >Verificar Pagos</b-button
        >
      </div>
    </div>
    <div class="container-table" v-if="pedidos.length > 0">
      <b-table
        striped
        :stacked="windowWidth < 600"
        hover
        :items="pedidosFiltered"
        :fields="fields"
        :busy="loadingData"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        sort-icon-left
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #cell(fecha_entrega_planificada)="data">
          <span
            >{{ data.item.fecha | fecha }} de
            {{ secondsToHM(data.item.hora_inicio) }} a
            {{ secondsToHM(data.item.hora_fin) }}</span
          >
        </template>
        <template #cell(status_pago)="data">
          <span>{{
            data.item.pago ? data.item.pago.status_del_pago : "---"
          }}</span>
        </template>
        <template #cell(solicitado)="data">
          <span>{{ data.item.created }}</span>
        </template>
        <template #cell(comprador)="data">
          <span
            >{{ data.item.nombre_usuario | todasLasPrimerasMayusculas }}
            {{ data.item.apellidos | todasLasPrimerasMayusculas }} -
            {{ data.item.telefono }}
          </span>
        </template>
        <template #cell(estado)="data">
          <div class="container-estado-pedido">
            <span class="mr-2">{{
              estadoDelPedido(data.item.estado_del_pedido).text
            }}</span>
            <div
              :style="`background-color:${
                estadoDelPedido(data.item.estado_del_pedido).color
              }`"
              class="bolita"
            ></div>
          </div>
        </template>
        <template #cell(total)="data">
          <span>${{ total(data.item.productos) | numero }}</span>
        </template>
        <template #cell(actions)="data">
          <b-button size="sm" @click="data.toggleDetails" class="mr-2 mb-1">
            {{ data.detailsShowing ? "Ocultar" : "Mostrar" }} Detalles
          </b-button>

          <b-button
            size="sm"
            @click="mostrarUbicacion(data.item)"
            class="mr-2 mb-1"
            >Ubicación</b-button
          >

          <b-button
            size="sm"
            @click="showModalChangeStatus(data.item)"
            class="mr-2 mb-1"
            >Cambiar estado</b-button
          >
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style="margin-left: 5px">Cargando Datos...</strong>
          </div>
        </template>
        <template #row-details="data">
          <b-card>
            <div class="container-data-detail">
              <span class="title-data-detail">Quien recibe:</span>
              <span class="data-detail"
                >{{ data.item.nombre_recibe }} - Tlf:{{
                  data.item.telefono_recibe
                }}</span
              >
            </div>
            <hr v-if="data.item.pago" />
            <div class="container-data-detail" v-if="data.item.pago">
              <span class="title-data-detail">Detalles del pago:</span>
              <span class="data-detail"
                >{{ data.item.pago.codigo_pago }} ---
                {{ data.item.pago.fecha_hora_pago | fechaHora }} --- ${{
                  data.item.pago.monto_pagado | numero
                }}
                -- {{ data.item.pago.metodo_de_pago }}</span
              >
            </div>
            <hr />
            <div class="container-data-detail">
              <span class="title-data-detail">Direccion:</span>
              <span class="data-detail">{{ data.item.direccion }}</span>
              <span class="title-data-detail">torre/manzana:</span>
              <span class="data-detail">{{ data.item.torre_manzana }}</span>
              <span class="title-data-detail">apto/casa:</span>
              <span class="data-detail">{{ data.item.apto_casa }}</span>
              <span
                class="title-data-detail"
                v-if="data.item.observaciones_direccion.length > 0"
                >observaciones:</span
              >
              <span
                class="data-detail"
                v-if="data.item.observaciones_direccion.length > 0"
                >{{ data.item.observaciones_direccion }}</span
              >
            </div>
            <hr />
            <p class="title-data-detail text-left">Productos:</p>
            <b-row
              class="mb-2 row-container"
              v-for="(producto, index) in data.item.productos"
              :key="index"
            >
              <span class="ml-2" style="font-size: 1.3rem"
                >{{ producto.cantidad }} -
              </span>
              <span class="ml-2" style="font-size: 0.8rem">{{
                producto.codigo_interno
              }}</span>
              <span class="ml-3">
                - {{ producto.producto | todasLasPrimerasMayusculas }}</span
              >
              <span class="ml-2 mr-3" style="font-size: 0.8rem"
                >( {{ producto.presentacion }} )</span
              >
              <span class="ml-2">{{
                (producto.cantidad * producto.precio_unitario) | numero
              }}</span>
              <span
                v-if="producto.es_adicion == 1"
                class="ml-3"
                style="font-size: 0.8rem"
                >(adición de <strong>{{ producto.de_quien_es_adicion }}</strong
                >)</span
              >
            </b-row>
            <b-button size="sm" @click="data.toggleDetails">Ocultar</b-button>
          </b-card>
        </template>
      </b-table>
    </div>
    <div class="container-paginator" v-if="pedidos.length > 0">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
      <span style="margin-left: 15px">Items por página</span>
      <b-form-select
        v-model="perPage"
        :options="optionsPerPage"
        style="width: 80px; margin-left: 10px"
      ></b-form-select>
    </div>
    <h2 v-if="pedidos.length == 0">No tiene pedidos</h2>
    <div class="container-mapa" v-show="showModalUbicacionPedido">
      <div class="google-map" ref="googleMap"></div>
      <div class="container-boton-map">
        <b-button
          size="sm"
          @click="showModalUbicacionPedido = false"
          class="mr-2"
          >Cerrar Mapa</b-button
        >
      </div>
    </div>
    <change-status-pedido
      @change="changeStatus($event)"
      :pedido="pedidoSeleccionado"
    />
  </div>
</template>

<script>
import PedidosService from "@/services/pedidos.service";
import DireccionesService from "@/services/direcciones.service";
import EventBus from "@/services/eventBus.js";
import { Helpers } from "@/mixins/Helpers";
import { mapGetters } from "vuex";
import GoogleMapsApiLoader from "google-maps-api-loader";
import WompiService from "@/services/wompi.service.js";
import PagosService from "@/services/pagos.service.js";
import ChangeStatusPedido from "@/components/AdminComponents/ChangeStatusPedido.vue";
export default {
  name: "AdminPedidos",
  mixins: [Helpers],
  components: { "change-status-pedido": ChangeStatusPedido },
  data() {
    return {
      wompiService: null,
      direccionesService: null,
      pagosService: null,
      pedidosService: null,
      sortBy: "solicitado",
      fields: [
        { key: "codigo_pedido", sortable: true },
        { key: "comprador", sortable: true },
        { key: "fecha_entrega_planificada", sortable: true },
        { key: "total", sortable: true },
        { key: "status_pago", sortable: true },
        { key: "estado", sortable: true },
        { key: "solicitado", sortable: true },
        { key: "actions", sortable: false },
      ],
      pedidos: [],
      windowWidth: window.innerWidth,
      loadingData: false,
      perPage: 10,
      currentPage: 1,
      optionsPerPage: [5, 10, 25, 50, 100],
      itemSeleted: null,
      sortDesc: false,
      pedidoUbicacion: null,
      showModalUbicacionPedido: false,
      map: null,
      google: null,
      mapConfig: {
        center: {
          lat: 4.683359696392844,
          lng: -74.08041937443866,
        },
        zoom: 8,
        streetViewControl: false,
        restriction: null,
      },
      center: {
        lat: 4.683359696392844,
        lng: -74.08041937443866,
      },
      marcador: null,
      poligonoCobertura: [],
      pedidoSeleccionado: null,
      estados: [
        { text: "Solicitado", value: 1 },
        { text: "Aceptado", value: 2 },
        { text: "En Produccion", value: 3 },
        { text: "En Entrega", value: 4 },
        { text: "Entregado", value: 5 },
        { text: "Cancelado", value: 6 },
      ],
      selectedStatus: [1, 2, 3, 4, 5, 6],
      fechaDesde: new Date().toISOString().split("T")[0].toString(),
    };
  },
  created() {
    this.pedidosService = new PedidosService();
    this.direccionesService = new DireccionesService();
    this.wompiService = new WompiService();
    this.pagosService = new PagosService();
  },
  async mounted() {
    this.fetchAreaCobertura();
    this.getItemsForList();
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: "AIzaSyCFnBjvp7-DXvPyohgfyFPUmRFH4o1CEUM",
    });
    this.google = googleMapApi;
    this.initializeMap();
    EventBus.$on("SHOW_MODAL_UBICACION", (payLoad) => {
      this.pedidoUbicacion = payLoad.pedido;
      this.showModalUbicacionPedido = payLoad.showModalUbication;
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    fetchAreaCobertura() {
      this.poligonoCobertura = [...this.direccionesService.getAreaCobertura()];
    },
    async verificarPedidos() {
      const pedidosNoAprobados = this.pedidos.filter((item) => {
        return !item.pago || item.pago.status_del_pago != "APPROVED";
      });
      for (let index = 0; index < pedidosNoAprobados.length; index++) {
        const item = pedidosNoAprobados[index];
        let dataForPago = null;
        await this.wompiService
          .verificarStatusPedido(item.codigo_pedido)
          .then((result) => {
            if (result.data.data.length > 0) {
              this.transaccionStatus = true;
              this.transaccionData = result.data.data[0];
              dataForPago = {
                codigo_pedido: result.data.data[0].reference,
                codigo_pago: result.data.data[0].id,
                fecha_hora_pago: result.data.data[0].created_at.substring(0, 19),
                monto_pagado: result.data.data[0].amount_in_cents / 100,
                metodo_de_pago: result.data.data[0].payment_method_type,
                descripcion_del_pago: result.data.data[0].payment_method
                  .payment_description
                  ? result.data.data[0].payment_method.payment_description
                  : "---",
                tipo_identificacion_del_que_paga: result.data.data[0]
                  .payment_method.user_legal_id_type
                  ? result.data.data[0].payment_method.user_legal_id_type
                  : "---",
                identificacion_del_que_paga: result.data.data[0].payment_method
                  .user_legal_id
                  ? result.data.data[0].payment_method.user_legal_id
                  : "---",
                status_del_pago: result.data.data[0].status,
              };
            }
          })
          .catch((err) => {});

        if (dataForPago) {
          await this.pagosService
            .registrarPago(dataForPago)
            .then((result) => {
              console.log(result);
            })
            .catch((err) => {});
        }
      }
      this.getItemsForList();
    },
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
      this.addZonaDeCobertura();
    },
    getItemsForList() {
      this.loadingData = true;
      this.pedidosService
        .pedidosAll()
        .then((result) => {
          this.pedidos = result.data.data.map((item) => {
            item.isActive = false;
            return item;
          });
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al recuperar items de la lista",
          });
        });
    },
    total(productos) {
      let suma = 0;
      productos.forEach((element) => {
        suma += element.cantidad * element.precio_unitario;
      });
      return suma;
    },
    mostrarUbicacion(pedido) {
      this.pedidoUbicacion = { ...pedido };
      this.showModalUbicacionPedido = !this.showModalUbicacionPedido;
      this.addMarker();
    },
    addMarker() {
      if (this.marcador) {
        this.marcador.setMap(null);
        this.marcador = null;
      }

      const data = this.pedidoUbicacion;
      let contentString = `
        <div>
            <p style="padding:0px; margin:0px;margin-top:2px;">${
              data.codigo_pedido
            }</p>
            <p style="padding:0px; margin:0px;margin-top:2px;">${
              data.fecha
            } de ${this.secondsToHM(data.hora_inicio)} a ${this.secondsToHM(
        data.hora_fin
      )}</p>
            <p style="padding:0px; margin:0px;margin-top:2px;">${
              data.direccion
            }</p>
            <p style="padding:0px; margin:0px;margin-top:2px;">torre/mnz: ${
              data.torre_manzana
            } apto/casa: ${data.apto_casa}</p>
            <p style="padding:0px; margin:0px;margin-top:2px;">${
              data.nombre_recibe
            }</p>
            <p style="padding:0px; margin:0px;margin-top:2px;">${
              data.telefono_recibe
            }</p>
            <div>
                <p style="padding:0px; margin:0px;margin-top:2px;font-weight:600">Productos</p>`;
      data.productos.forEach((item) => {
        contentString += `<p style="padding:0px; margin:0px;">${
          item.cantidad
        } ${item.producto} - ${item.presentacion} ${
          item.es_adicion == 1 ? "(adición)" : ""
        }</p>`;
      });
      contentString += `</div>
        </div>
      `;
      const infowindow = new this.google.maps.InfoWindow({
        content: contentString,
      });

      const objectMarcador = new this.google.maps.Marker({
        position: {
          lat: data.cy,
          lng: data.cx,
        },
        map: this.map,
        draggable: false,
        title: data.codigo_pedido,
        animation: this.google.maps.Animation.DROP,
      });

      objectMarcador.addListener("click", () => {
        infowindow.open({
          anchor: objectMarcador,
          map: this.map,
          shouldFocus: false,
        });
      });

      this.marcador = objectMarcador;
      this.ajustarmapa();
    },
    addZonaDeCobertura() {
      this.objectPoligonoCobertura = new this.google.maps.Polygon({
        paths: this.poligonoCobertura,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.1,
      });
      this.objectPoligonoCobertura.setMap(this.map);
    },
    ajustarmapa() {
      let bounds = new this.google.maps.LatLngBounds();
      bounds.extend(this.marcador.getPosition());
      this.map.fitBounds(bounds);
      this.map.setZoom(16);
    },
    changeStatus(event) {
      this.pedidos.forEach((item, index) => {
        if (item.id === event.pedido.id) {
          this.pedidos[index].estado_del_pedido = event.estado;
        }
      });
    },
    showModalChangeStatus(item) {
      this.pedidoSeleccionado = item;
      this.$bvModal.show("modal-change-status-pedido");
    },
  },
  computed: {
    ...mapGetters(["getToken"]),
    rows() {
      return this.pedidos.length;
    },
    pedidosFiltered() {
      return this.pedidos.filter((item) => {
        console.log(this.fechaDesde);
        return (
          this.selectedStatus.indexOf(item.estado_del_pedido) != -1 &&
          this.$moment(item.fecha).isSameOrAfter(this.fechaDesde)
        );
      });
    },
  },
  watch: {
    getToken() {
      this.getItemsForList();
    },
  },
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  min-height: 700px;
  padding: 0px;
}

.container-table {
  width: 100%;
  min-height: 300px;
  padding-bottom: 20px;
}
.container-paginator {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  padding-bottom: 20px;
}
.container-actions-top-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
}
.row-container {
  display: flex;
  align-items: center;
}
.bolita {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid black;
}
.container-estado-pedido {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-data-detail {
  display: flex;
  flex-wrap: wrap;
}
.title-data-detail {
  font-weight: 600;
  margin-right: 5px;
}
.data-detail {
  margin-right: 10px;
}

.container-mapa {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0px auto;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 66;
  background-color: rgba(0, 0, 0, 0.616);
}
.google-map {
  height: 80%;
  width: 100%;
}
.container-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
}
.container-boton-map {
  display: flex;
  width: 100%;
  justify-content: center;
}
.container-calendar {
  display:flex;
  align-items:center;
}
.separador {
  display: none;
  border:1px solid rgb(197, 197, 197);
  width:100%
}
@media (max-width: 1400px) {
  .container-cental {
    padding: 0px 10px;
  }
}
@media (max-width: 470px) {
  .container-data-detail {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .container-paginator {
    flex-direction: column;
    align-items: center;
  }
  .separador {
    display: block;  
  }
}
</style>
