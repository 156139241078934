<template>
  <b-modal
    id="modal-change-status-pedido"
    title="Cambio de Estado"
    ref="modal-change-status-pedido"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        id="campoEstados"
        label="Estado:"
        label-for="campoEstados"
        invalid-feedback="Debes seleccionar un estado"
        :state="campoEstadoState"
      >
        <b-form-select
          id="campoEstados"
          v-model="estadoSeleccionado"
          :options="estadosDisponibles"
          ref="campoEstados"
          :state="campoEstadoState"
          required
        ></b-form-select>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import PedidosService from "@/services/pedidos.service";
import EventBus from "@/services/eventBus.js";
export default {
  name: "ChangeStatusPedido",
  props: { pedido: Object },
  data() {
    return {
      pedidosService: null,
      estados: [
        { text: "Solicitado", value: 1 },
        { text: "Aceptado", value: 2 },
        { text: "En Produccion", value: 3 },
        { text: "En Entrega", value: 4 },
        { text: "Entregado", value: 5 },
        { text: "Cancelado", value: 6 },
      ],
      estadoSeleccionado: null,
      campoEstadoState: null,
    };
  },
  created() {
    this.pedidosService = new PedidosService();
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.campoEstadoState = valid;
      return valid;
    },
    resetModal() {
      this.estadoSeleccionado = null;
      this.campoEstadoState = null;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      //cambiar el estado en el back
      this.pedidosService
        .changeStatus({
          id: this.pedido.id,
          estado_del_pedido: this.estadoSeleccionado,
        })
        .then((result) => {
          console.log(result);
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Cambio de Estado",
            variant: "success",
            message: "Se cambio el estado satisfactoriamente",
          });
          this.$emit("change", {
            pedido: this.pedido,
            estado: this.estadoSeleccionado,
          });
          this.$nextTick(() => {
            this.$bvModal.hide("modal-change-status-pedido");
          });
        })
        .catch((err) => {
          console.error(err);
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "warning",
            message: "Error al cambiar estado del pedido",
          });
        });
    },
  },
  computed: {
    estadosDisponibles() {
      if (!this.pedido) {
        return [];
      }
      return this.estados.filter((item) => {
        return item.value != this.pedido.estado_del_pedido;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
