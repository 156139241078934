import axios from "axios";

export default class WompiService {
  verificarStatus(id){
    return axios.get(process.env.VUE_APP_API_WOMPI_VERIFI+ id)
  }  
  verificarStatusPedido(codigo){
    const headers = {
      headers: {
        Authorization: 'Bearer ' + process.env.VUE_APP_API_WOMPI_PRIVATE_KEY
      }
     }
    return axios.get(process.env.VUE_APP_API_WOMPI_VERIFI+`?reference=${codigo}`,headers)
  }  
}
